import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { Device } from "./../components/theme"

import Layout from "./../components/layout"
import NavGrid from "./../components/navGrid"
import SEO from "./../components/SEO"

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(8,1fr);
    grid-gap: 2rem;

    @media ${Device.mobile} {
      grid-template-columns: 1fr;
    }
`

const Content = styled.div`
    display: grid;
    grid-column-start: 3;
    grid-column-end: 7;
    line-height: 1.5;

    @media ${Device.mobile} {
      margin: 1rem;
      grid-column: 1;
    }
`

const MarkdownTemplate = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <NavGrid />
      <Grid>
        <Content dangerouslySetInnerHTML={{ __html: html }} />
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`

export default MarkdownTemplate